<div class="column gap center text-align-center">
  @if (status === 'trying') {
    <h3>{{ 'courseDetails.redirectModal.title' | translate }}</h3>
    <p class="text-md">{{ 'courseDetails.redirectModal.text' | translate }}</p>
  } @else {
    <ion-icon src="/assets/icons/error-colored.svg" class="icon" aria-hidden="true"></ion-icon>
    <h3>{{ 'courseDetails.redirectModal.error.title' | translate }}</h3>
    <p class="text-md">{{ 'courseDetails.redirectModal.error.text' | translate }}</p>
    <button class="gap-sm btn" mat-button class="btn--primary" (click)="retry$.next()">
      <ion-icon name="reload-outline" size="small"></ion-icon>
      {{ 'courseDetails.redirectModal.error.retry' | translate }}
    </button>
    <a class="text-btn grey-color-500 bold" mat-dialog-close>{{ 'global.close' | translate }}</a>
  }
</div>
