import { Component, inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { concat, EMPTY, of, Subject, timer, zip } from 'rxjs';
import { catchError, map, skip, startWith, switchMap, tap } from 'rxjs/operators';
import { IonIcon } from 'src/directives/icon';
import { environment } from 'src/environments/environment';
import { Course } from 'src/models/course';
import { CustomerService } from 'src/services/customer.service';
import { UserService } from 'src/services/user.service';
import { HelpCenterTypes } from 'src/utils/enums';
import { getStorageObject } from 'src/utils/storage-manager';

import { CourseService } from '../../../../services/course.service';

export interface RedirectionModalData {
  course: Course;
}

@Component({
  selector: 'app-redirection-modal',
  templateUrl: './redirection-modal.component.html',
  styleUrls: ['./redirection-modal.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, TranslateModule, IonIcon]
})
export class RedirectionModalComponent implements OnDestroy {
  public data = inject<RedirectionModalData>(MAT_DIALOG_DATA);
  private customerService = inject(CustomerService);
  private userService = inject(UserService);
  private courseService = inject(CourseService);

  public helpCenters = HelpCenterTypes;

  public status: 'trying' | 'failed' = 'trying';

  public retry$ = new Subject<void>();

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private getAccessToken() {
    const accessToken: string = getStorageObject(environment.accessTokenStorage);
    const refreshAccessToken = getStorageObject(environment.accessRefreshTokenStorage);

    return refreshAccessToken
      ? this.userService.refreshAuthToken().pipe(map(({ accessToken }) => accessToken))
      : of(accessToken);
  }

  private sub = this.retry$
    .pipe(
      startWith(null),
      tap(() => {
        this.status = 'trying';
      }),
      switchMap(() =>
        zip(
          this.customerService.getCustomersWithLine().pipe(
            map((domains) => {
              const domain = domains.items.find((item) => item.id === this.data.course.institution.customerId)?.domain;

              if (!domain) {
                throw new Error('Domain not found');
              }

              return domain;
            })
          ),
          this.getAccessToken(),
          concat(
            timer(5000),
            this.courseService.isEnrolled(this.data.course.code as string).pipe(
              map((isEnrolled) => {
                if (!isEnrolled) {
                  throw new Error('User is not enrolled');
                }
              })
            )
          ).pipe(skip(1))
        ).pipe(
          catchError(() => {
            this.status = 'failed';

            return EMPTY;
          })
        )
      )
    )
    .subscribe(([domain, accessToken]) => {
      // Redirect to the university domain with the course and token, so the user
      // is automatically logged in in that domain and redirected to the course.
      //
      // In localhost, this won't work because the `domain` returned by the API
      //   is `localhost:500X` instead of `localhost:420X`.
      // If you want to test this, after the redirection, you'll have to manually
      //   change the port to `:420X`.

      window.location.href = `//${domain}/autologin?course=${this.data.course.idSimo}&token=${accessToken}`;
    });
}
